// @flow
/* eslint-disable jsx-a11y/anchor-has-content,jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from "react";
import type {translate} from "../i18n/i18n";
import type DetailedProduct from "../models/DetailedProduct";
import {withTranslation} from "react-i18next";
import "./ProductPage.scss";
import {Autoplay, Navigation, Pagination} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";
import PriceBoxContainer from "../container/PriceBoxContainer";
import ProductSwiperSlide from "./ProductSwiperSlide";
import {useParams} from "react-router";
import Loading from "./Loading";
import {getBtnTextColor} from "../helper/setTextColor";
import Zoom from "./Zoom";
import type {Image} from "../models/Attachments";
import detachMouseEvents from "../helper/SwiperUtil.cs";

type ProductPageProps = {
	t: translate,
	detailedProduct: DetailedProduct,
	resetProductScope: () => void,
	setProductScope: string => void,
	hasMultipleGalleryImages: boolean,
	goBack: () => void,
	filter: {},
	disclaimer: string,
	images: Image[],
}

const ProductPage = (props: ProductPageProps) => {
	const [showImageModal, setImageModal] = useState(false);
	const [modalImage, setModalImage] = useState("");
	const {detailedProduct, setProductScope, images} = props;
	const {productId} = useParams();

	const ZoomProps = {img: modalImage.attachmentUrl, width: 600, height: 600, zoomScale: 3};

	useEffect(() => {
		if (detailedProduct == null || detailedProduct.product.id !== productId) {
			setProductScope(productId);
		}
	}, [productId, detailedProduct, setProductScope]);

	const handleBack = e => {
		e.preventDefault();
		props.goBack(props.filter);
	};

	const showImage = imageUrl => {
		setModalImage(imageUrl);
		toggleImageModal();
	};

	const toggleImageModal = () => {
		setImageModal(!showImageModal);
	};

	return detailedProduct
		? <React.Fragment>
			<div className="detail" data-testid={"productDetail"}>
				<div className="control">
					<span><a className="toggle-detail" style={{color: getBtnTextColor()}} data-testid={"backButton"}
					         onClick={e => handleBack(e)}>{props.t("navigation.back")}</a></span>
					<span><a className="share-button" style={{display: "none"}}>{props.t("navigation.share")}</a></span>
				</div>
				<div className="contain">
					<div className="gallery" data-testid={"productGallery"}>
						<div className={"swiper"}>
							<Swiper
								modules={[Navigation, Pagination]}
								className={"swiper-container swiper-gal"}
								slidesPerView={1}
								spaceBetween={0}
								slidesPerGroup={1}
								autoHeight={false}
								loopAdditionalSlides={8}
								loop={true}
								loopFillGroupWithBlank={true}
								grabCursor={true}
								pagination={{
									el: ".swiper-pagination",
									clickable: true,
								}}
								navigation={{
									nextEl: ".swiper-button-next-gal",
									prevEl: ".swiper-button-prev-gal",
								}}
							>
								{images.map((it, index) =>
									<SwiperSlide className="swiper-slide" key={"image_" + index}>
										<div className="img"
											 style={{backgroundImage: `url('${it.attachmentUrl || it.previewImageUrl}')`}}>
											<a className="iconzoom"
											   onClick={() => showImage(it)}/>
										</div>
									</SwiperSlide>
								)}
							</Swiper>
							<div
								className={`swiper-button-next swiper-button-next-gal ${!props.hasMultipleGalleryImages ? "gal-hidden" : ""}`}/>
							<div
								className={`swiper-button-prev swiper-button-prev-gal ${!props.hasMultipleGalleryImages ? "gal-hidden" : ""}`}/>
							<div
								className={`swiper-pagination swiper-pagination-gal ${!props.hasMultipleGalleryImages ? "gal-hidden" : ""}`}/>
							{images.map((it, index) =>
								<style key={"teaser_style_" + index}>
									{`.swiper-pagination-gal .swiper-pagination-bullet:nth-child(${index + 1}) {`}
									{`background-image: url('${it.previewImage ? it.previewImage.attachmentUrl : it.attachmentUrl}') !important;`}
									{"}"}
								</style>
							)}
						</div>
					</div>
					<div className="descript">
						<div className="block">
							<div className="title" data-testid={"detailsTitle"}>
								{props.detailedProduct.product.name}
							</div>
							<div>
								<small data-testid={"detailsArticleNo"}>{props.t("productDetail.articleNumber")}: {props.detailedProduct.product.articleNumber}</small>
							</div>
							<PriceBoxContainer/>
							<details className={"description"}>
								<summary>{props.t("productDetail.infoBox.description")}</summary>
								<div dangerouslySetInnerHTML={{__html: props.detailedProduct.product.description}} data-testid={"detailsDescription"}/>
							</details>
							<details>
								<summary>{props.t("productDetail.infoBox.vehicles")}</summary>
								<div className="carline" data-testid={"detailsModels"}>
									{
										props.detailedProduct.product.fitments.length === 0
											? <span>
												{props.t("productDetail.infoBox.empty")}
											</span>
											: <ul>
												{props.detailedProduct.product.fitments.map(vehicle => <li
													key={vehicle}>{vehicle}</li>)
												}
											</ul>
									}
								</div>
							</details>
						</div>
						<div className="head">
							<span>{props.t("productDetail.relatedArticle")}</span>
							<span></span>
						</div>
						<div className={"swiper"} data-testid={"relatedProducts"}>
							<Swiper
								onTouchEnd={swiper => {
									detachMouseEvents(swiper);
									swiper.autoplay.stop();
								}}
								modules={[Navigation, Autoplay]}
								className={"swiper-container swiper-ref"}
								slidesPerView={1}
								spaceBetween={5}
								slidesPerGroup={1}
								autoHeight={true}
								loopAdditionalSlides={5}
								loop={true}
								loopFillGroupWithBlank={true}
								grabCursor={true}
								navigation={{
									nextEl: ".swiper-button-next-ref",
									prevEl: ".swiper-button-prev-ref",
								}}
								autoplay={{delay: 3000, pauseOnMouseEnter: true, disableOnInteraction: false}}
								onResize={swiper => {
									const node = document.querySelector(".swiper-ref > .swiper-wrapper");
									if (node) node.style.height = "auto";
									swiper.updateAutoHeight();
								}}
							>
								{(props.detailedProduct.relatedProducts.map((product, index) =>
									<ProductSwiperSlide product={product} key={"product_" + index}/>
								))}
							</Swiper>
							<div className="swiper-button-next swiper-button-next-ref" tabIndex={"0"} role={"button"}
							     aria-label={"Next slide"}/>
							<div className="swiper-button-prev swiper-button-prev-ref" tabIndex={"0"} role={"button"}
							     aria-label={"Next slide"}/>
						</div>
						<div className={"swiper"}>
							{props.detailedProduct.relatedProducts.map(product =>
								<div key={product.id}/>
							)}
						</div>
					</div>
					{
						showImageModal &&
						<div className="zoom">
							<Zoom {...ZoomProps}/>
							<div className="iconclose" onClick={() => toggleImageModal()}></div>
						</div>
					}
				</div>
			</div>
			<footer>
				<span>{props.disclaimer}</span>
			</footer>
		</React.Fragment>
		: <Loading/>;
};

export default withTranslation()(ProductPage);
